<template>
  <b-overlay
    :show="item === null"
    rounded="sm"
  >
    <b-form
      v-if="item"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.header_menu_zone1.label')"
            label-for="header_menu_zone1"
            :state="errors && errors.header_menu_zone1 ? false : null"
          >
            <v-select
              id="header_menu_zone1"
              v-model="item.header_menu_zone1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="navigationOptions"
              :reduce="val => val.value"
              input-id="header_menu_zone1"
              :searchable="true"
              @search="searchNavigation"
            />
            <b-form-invalid-feedback v-if="errors && errors.header_menu_zone1">
              {{ errors.header_menu_zone1[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.header_menu_zone2.label')"
            label-for="header_menu_zone2"
            :state="errors && errors.header_menu_zone2 ? false : null"
          >
            <v-select
              id="header_menu_zone2"
              v-model="item.header_menu_zone2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="navigationOptions"
              :reduce="val => val.value"
              input-id="header_menu_zone2"
              :searchable="true"
              @search="searchNavigation"
            />
            <b-form-invalid-feedback v-if="errors && errors.header_menu_zone2">
              {{ errors.header_menu_zone2[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.header_menu_zone3.label')"
            label-for="header_menu_zone3"
            :state="errors && errors.header_menu_zone3 ? false : null"
          >
            <v-select
              id="header_menu_zone3"
              v-model="item.header_menu_zone3"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="navigationOptions"
              :reduce="val => val.value"
              input-id="header_menu_zone3"
              :searchable="true"
              @search="searchNavigation"
            />
            <b-form-invalid-feedback v-if="errors && errors.header_menu_zone3">
              {{ errors.header_menu_zone3[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.footer_menu_zone1.label')"
            label-for="footer_menu_zone1"
            :state="errors && errors.footer_menu_zone1 ? false : null"
          >
            <v-select
              id="footer_menu_zone1"
              v-model="item.footer_menu_zone1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="navigationOptions"
              :reduce="val => val.value"
              input-id="footer_menu_zone1"
              :searchable="true"
              @search="searchNavigation"
            />
            <b-form-invalid-feedback v-if="errors && errors.footer_menu_zone1">
              {{ errors.footer_menu_zone1[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.footer_menu_zone2.label')"
            label-for="footer_menu_zone2"
            :state="errors && errors.footer_menu_zone2 ? false : null"
          >
            <v-select
              id="footer_menu_zone2"
              v-model="item.footer_menu_zone2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="navigationOptions"
              :reduce="val => val.value"
              input-id="footer_menu_zone2"
              :searchable="true"
              @search="searchNavigation"
            />
            <b-form-invalid-feedback v-if="errors && errors.footer_menu_zone2">
              {{ errors.footer_menu_zone2[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.footer_menu_zone3.label')"
            label-for="footer_menu_zone3"
            :state="errors && errors.footer_menu_zone3 ? false : null"
          >
            <v-select
              id="footer_menu_zone3"
              v-model="item.footer_menu_zone3"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="navigationOptions"
              :reduce="val => val.value"
              input-id="footer_menu_zone3"
              :searchable="true"
              @search="searchNavigation"
            />
            <b-form-invalid-feedback v-if="errors && errors.footer_menu_zone3">
              {{ errors.footer_menu_zone3[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.global_contacts.label')"
            label-for="global_contacts"
            :state="errors && errors.global_contacts ? false : null"
          >
            <v-select
              id="global_contacts"
              v-model="item.global_contacts"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="shopOptions"
              :reduce="val => val.value"
              input-id="global_contacts"
              :searchable="true"
              @search="searchShops"
            />
            <b-form-invalid-feedback v-if="errors && errors.global_contacts">
              {{ errors.global_contacts[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  components: {
    BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, vSelect,
  },
  data() {
    return {
      item: {
        header_menu_zone1: null,
        header_menu_zone2: null,
        header_menu_zone3: null,
        footer_menu_zone1: null,
        footer_menu_zone2: null,
        footer_menu_zone3: null,
        global_contacts: null,
      },
      navigationOptions: [],
      shopOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/administration/contact_information').then(response => {
      const { data } = response.data

      if (Object.keys(data).length === 0) {
        return
      }

      this.item = data

      if (!_.isNull(data.headerMenu1) && !_.find(this.navigationOptions, { value: data.headerMenu1.id })) {
        this.navigationOptions.push({
          value: data.headerMenu1.id,
          label: data.headerMenu1.title,
        })
      }

      if (!_.isNull(data.headerMenu2) && !_.find(this.navigationOptions, { value: data.headerMenu2.id })) {
        this.navigationOptions.push({
          value: data.headerMenu2.id,
          label: data.headerMenu2.title,
        })
      }

      if (!_.isNull(data.headerMenu3) && !_.find(this.navigationOptions, { value: data.headerMenu3.id })) {
        this.navigationOptions.push({
          value: data.headerMenu3.id,
          label: data.headerMenu3.title,
        })
      }

      if (!_.isNull(data.footerMenu1) && !_.find(this.navigationOptions, { value: data.footerMenu1.id })) {
        this.navigationOptions.push({
          value: data.footerMenu1.id,
          label: data.footerMenu1.title,
        })
      }

      if (!_.isNull(data.footerMenu2) && !_.find(this.navigationOptions, { value: data.footerMenu2.id })) {
        this.navigationOptions.push({
          value: data.footerMenu2.id,
          label: data.footerMenu2.title,
        })
      }

      if (!_.isNull(data.footerMenu3) && !_.find(this.navigationOptions, { value: data.footerMenu3.id })) {
        this.navigationOptions.push({
          value: data.footerMenu3.id,
          label: data.footerMenu3.title,
        })
      }

      if (!_.isNull(data.globalContacts) && !_.find(this.shopOptions, { value: data.globalContacts.id })) {
        this.shopOptions.push({
          value: data.globalContacts.id,
          label: data.globalContacts.title,
        })
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async searchNavigation(query) {
      await this.$http.get('/api/administration/navigations', {
        params: {
          query,
        },
      })
        .then(response => {
          this.navigationOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchShops(query) {
      await this.$http.get('/api/administration/shops', {
        params: {
          query,
        },
      })
        .then(response => {
          this.shopOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async submit() {
      await this.$http.post('/api/administration/contact_information', this.item)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
